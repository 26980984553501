import gql from "graphql-tag";
import * as fragment from "../fragments/index";
export const getUsers = gql`
  query getUsers {
    users {
      ...User
    }
  }
  ${fragment.UserFragment}
`;

export const User = gql`
  query me {
    me {
      ...User
    }
  }
  ${fragment.UserFragment}
`;

export const teams = gql`
  query teams {
    teams {
      ...Team
    }
  }
  ${fragment.TeamFragment}
`;

export const jobTitles = gql`
  query jobTitles {
    jobTitles {
      ...JobTitle
    }
  }
  ${fragment.JobTitleFragment}
`;

export const projects = gql`
  query projects {
    projects {
      ...Project
    }
  }
  ${fragment.ProjectFragment}
`;

export const projectsByDepartment = gql`
  # department - string case no matter
  query projectsByDepartment($department: String) {
    projectsByDepartmen(department: $department) {
      ...Project
    }
  }
  ${fragment.ProjectFragment}
`;

export const myProjects = gql`
  query myProjects {
    myProjects {
      id
      name
    }
  }
`;

export const workStatistics = gql`
  query workStatistics($projectId: ID, $userId: ID, $dateParam: Date) {
    workStatistics(
      userId: $userId
      projectId: $projectId
      dateParam: $dateParam
    )
    # dateParam : YYYY-MM-DD
  }
`;
export const myStatisticsPerDwm = gql`
  query myStatisticsPerDwm {
    myStatisticsPerDwm {
      dailyReport {
        workedTime
        workingTime
      }
      monthlyReport {
        workedTime
        workingTime
      }
      weeklyReport {
        workedTime
        workingTime
      }
    }
  }
`;

export const userStatisticsByProject = gql`
  query userStatisticsByProject($dateParam: Date, $userId: ID) {
    userStatisticsByProject(dateParam: $dateParam, userId: $userId)
  }
`;
export const myStatPerDwmForProj = gql`
  query myStatPerDwmForProj($dateParam: Date, $projectId: ID) {
    myStatPerDwmForProj(dateParam: $dateParam, projectId: $projectId) {
      name
      id
      dailyReport {
        dailyReportId
        statisticsForTheDay
        timeStatistics {
          workedTime
          workingTime
        }
      }
    }
  }
`;

export const workerProjectsByDay = gql`
  query workerProjectsByDay($userId: ID, $dateParam: [Date]) {
    workerProjectsByDay(userId: $userId, dateParam: $dateParam) {
      id
      reportId
      name
      trackedTime
      date
      workDone {
        id
        text
        spendTime
      }
    }
  }
`;

export const getNotificationsForUser = gql`
  query getNotificationsForUser($page: Int, $number: Int) {
    getNotificationsForUser(page: $page, number: $number) {
      page
      pages
      isNew
      objects {
        redirectTo
        id
        title
        createdAt
        user {
          id
          firstName
          lastName
          avatar
        }
      }
    }
  }
`;

export const getPollByCompany = gql`
  query getPollByCompany($id: ID) {
    getPollByCompany(id: $id) {
      id
      question
      isAnonymous
      isMultipleChoice
      totalVotes
      isUsersAddOption
      choices {
        id
        choiceText
        voted
        users {
          id
          user {
            id
            firstName
            lastName
            avatar
          }
        }
      }
      userChoice {
        id
        answer
        choice {
          id
        }
      }
      createdAt
      startDate
      endDate
      answers {
        id
        answer
        user {
          id
          firstName
          lastName
          avatar
        }
      }
      totalUniqueVotes
      totalUsers
      allAnswers {
        id
        answer
        user {
          id
          firstName
          lastName
          avatar
        }
      }
    }
  }
`;

export const getUserUnvotedPolls = gql`
  query getUserUnvotedPolls($page: Int) {
    getUserUnvotedPolls(page: $page) {
      page
      pages
      objects {
        id
      }
    }
  }
`;
export const isValidTenantCompany = gql`
  query isValidTenantCompany($tenant: String!) {
    isValidTenantCompany(tenant: $tenant)
  }
`;
