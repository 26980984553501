export const INSTALL = "/install";
export const SIGN_IN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const PROJECTS = "/projects";
export const DASHBOARD = "/tracker";
export const ERROR = "/error";
export const REPORT = "/report";
export const REPORT_SUCCESS = "/report-success";
export const POLL = "/poll";
export const PROCESS_TOKEN = "/process-token-app";
