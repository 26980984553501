import React, { lazy, Suspense, useState } from "react";
import { Switch, Route, Redirect } from "react-router";
import * as path from "./constants/routes";
import Main from "./components/Main";
import Loader from "./components/Loader";
import i18n from "./i18n";
import ProcessToken from "pages/PublicPages/ProcessToken";
const Login = lazy(() => import("./pages/PublicPages/Login/index"));
const NetworkError = lazy(
  () => import("./pages/PublicPages/NetworkError/networkError"),
);
const ForgotPassword = lazy(
  () => import("./pages/PublicPages/ForgotPassword/ForgotPassword"),
);
const ResetPassword = lazy(
  () => import("./pages/PublicPages/ResetPassword/ResetPassword"),
);
const Install = lazy(() => import("./pages/PublicPages/Install/Install"));
const Application = lazy(() => import("./pages/PrivatePages/Application"));
const ChooseProjects = lazy(
  () => import("./pages/PrivatePages/ChooseProjects/ChooseProjects"),
);
const ReportPage = lazy(
  () => import("./pages/PrivatePages/ReportPage/ReportPage"),
);
const ReportSuccess = lazy(
  () => import("./pages/PrivatePages/ReportSuccess/ReportSucceess"),
);
const PageNotFound = lazy(() => import("./components/PageNotFound"));

export default () => {
  const [language, setLanguage] = useState("en");

  i18n.changeLanguage(language);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(language);
  };

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Redirect exact from="/" to={path.PROJECTS} />
        <Route exact path={path.SIGN_IN} component={Login} />
        <Route exact path={path.ERROR} component={NetworkError} />
        <Route exact path={path.INSTALL} component={Install} />
        <Route exact path={path.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={path.RESET_PASSWORD} component={ResetPassword} />
        <Route path={path.PROCESS_TOKEN} component={ProcessToken} />
        <Main handleLanguageChange={handleLanguageChange}>
          {(user) => (
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route
                  path={path.DASHBOARD}
                  render={(props) => (
                    <Application {...props} user={user.user} />
                  )}
                />
                <Route
                  path={path.PROJECTS}
                  render={(props) => (
                    <ChooseProjects {...props} user={user.user} />
                  )}
                />
                <Route
                  path={path.REPORT}
                  render={(props) => <ReportPage {...props} user={user.user} />}
                />
                <Route
                  path={path.REPORT_SUCCESS}
                  render={(props) => (
                    <ReportSuccess {...props} user={user.user} />
                  )}
                />
                <Route render={(props) => <PageNotFound {...props} />} />
              </Switch>
            </Suspense>
          )}
        </Main>
      </Switch>
    </Suspense>
  );
};
