import gql from "graphql-tag";
import * as fragment from "../fragments/index";

export const login = gql`
  mutation login($username: String!, $password: String!, $workspace: String) {
    login(username: $username, password: $password, workspace: $workspace) {
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
      token
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const editUser = gql`
  mutation editUser(
    $firstName: String
    $lastName: String
    $email: String!
    $avatar: String
  ) {
    editUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      avatar: $avatar
    ) {
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
      user {
        ...User
      }
    }
  }
  ${fragment.UserFragment}
`;

export const verifyToken = gql`
  mutation verifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($token: String!) {
    refreshToken(token: $token) {
      token
      payload
    }
  }
`;

export const confirmEmail = gql`
  mutation confirmEmail($email: String!) {
    confirmEmail(email: $email) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`;

export const sendActivationCode = gql`
  mutation sendActivationCode($email: String!) {
    sendActivationCode(email: $email) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`;

export const checkActivationCode = gql`
  mutation checkActivationCode($email: String, $code: Int) {
    checkActivationCode(email: $email, code: $code) {
      ok
      errors
      user {
        id
      }
    }
  }
`;

export const resetPassword = gql`
  mutation resetPassword(
    $newPassword1: String!
    $newPassword2: String!
    $confirmToken: String
    $activationCode: Int
    $userId: Int!
  ) {
    resetPassword(
      newPassword1: $newPassword1
      newPassword2: $newPassword2
      confirmToken: $confirmToken
      activationCode: $activationCode
      userId: $userId
    ) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`;

export const changePassword = gql`
  mutation changePassword(
    $oldPassword: String!
    $newPassword1: String!
    $newPassword2: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      ok
      errors
    }
  }
`;

export const changeProjectStatus = gql`
  mutation changeProjectStatus($input: ProjectStatusInput!) {
    changeProjectStatus(input: $input) {
      ok
      errors
    }
  }
`;

export const CALCULATE_WORK_DAY = gql`
  mutation calculateWorkDay(
    $projectId: ID!
    $seconds: Float!
    $date: Date!
    $sessionId: String!
    $timestamp: String!
    $online: Boolean!
  ) {
    calculateWorkDay(
      projectId: $projectId
      seconds: $seconds
      date: $date
      sessionId: $sessionId
      timestamp: $timestamp
      online: $online
    ) {
      ok
      errors
      statistics {
        userStatistics
      }
    }
  }
`;

export const createReportPoint = gql`
  mutation createReportPoint(
    $dailyReport: ID!
    $text: String!
    $spendTime: Time!
  ) {
    createReportPoint(
      dailyReport: $dailyReport
      text: $text
      spendTime: $spendTime
    ) {
      success
      error {
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`;

export const editReportPoint = gql`
  mutation editReportPoint(
    $reportPointId: Int!
    $dailyReport: Int
    $text: String
    $spendTime: Time
  ) {
    editReportPoint(
      reportPointId: $reportPointId
      dailyReport: $dailyReport
      text: $text
      spendTime: $spendTime
    ) {
      success
      error {
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
      reportPoint {
        id
        dailyReport {
          id
        }
        text
        spendTime
      }
    }
  }
`;

export const changeDailyReportWorkplaceStatus = gql`
  mutation changeDailyReportWorkplaceStatus(
    $status: Int!
    $dailyReportId: ID!
  ) {
    changeDailyReportWorkplaceStatus(
      status: $status
      dailyReportId: $dailyReportId
    ) {
      success
      errors
    }
  }
`;

export const updateReport = gql`
  mutation updateReport(
    $reportId: Int
    $userId: Int
    $projectId: Int
    $dateParam: Date
    $reportPoints: [ReportPointInput]
  ) {
    updateReport(
      reportId: $reportId
      userId: $userId
      projectId: $projectId
      dateParam: $dateParam
      reportPoints: $reportPoints
    ) {
      success
      reports {
        id
        text
        spendTime
      }
      errors
    }
  }
`;

export const deleteReportPoint = gql`
  mutation deleteReportPoint($reportPointId: ID!) {
    deleteReportPoint(reportPointId: $reportPointId) {
      success
      errors
    }
  }
`;

export const retractVote = gql`
  mutation retractVote($pollId: ID!) {
    retractVote(pollId: $pollId) {
      success
      errors
    }
  }
`;

export const voteInPoll = gql`
  mutation voteInPoll($pollId: ID!, $answer: String, $choiceIds: [ID]) {
    voteInPoll(pollId: $pollId, answer: $answer, choiceIds: $choiceIds) {
      success
      errors
    }
  }
`;

export const userDoNotParticipate = gql`
  mutation userDoNotParticipate($pollId: ID!) {
    userDoNotParticipate(pollId: $pollId) {
      success
      errors
    }
  }
`;

export const saveUserViewedNotifications = gql`
  mutation saveUserViewedNotifications {
    saveUserViewedNotifications {
      success
      errors
    }
  }
`;

export const changeUserStatus = gql`
  mutation changeUserStatus($online: Boolean!) {
    changeUserStatus(online: $online) {
      errors
      ok
    }
  }
`;
